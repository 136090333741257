var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=c5t5wnxp6mvabdrpn7wm4zt2zu/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "empty-topic-display";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li role=\"separator\" class=\"popover-menu-separator\"></li>\n        <li role=\"none\" class=\"popover-menu-list-item\">\n            <div role=\"group\" class=\"tab-picker popover-menu-tab-group\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic visibility",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":84},"end":{"line":10,"column":109}}}))
    + "\">\n                <input type=\"radio\" id=\"sidebar-topic-muted-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "MUTED", {"start":{"line":11,"column":152},"end":{"line":11,"column":181}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":11,"column":191},"end":{"line":11,"column":243}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":185},"end":{"line":11,"column":259}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-muted-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":144},"end":{"line":12,"column":157}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":179},"end":{"line":12,"column":192}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-mute-new\" aria-hidden=\"true\"></i>\n                </label>\n                <input type=\"radio\" id=\"sidebar-topic-inherit-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "INHERIT", {"start":{"line":15,"column":154},"end":{"line":15,"column":185}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":195},"end":{"line":15,"column":249}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":189},"end":{"line":15,"column":265}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-inherit-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":146},"end":{"line":16,"column":162}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":184},"end":{"line":16,"column":200}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-inherit\" aria-hidden=\"true\"></i>\n                </label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"stream_muted"),lookupProperty(depth0,"topic_unmuted"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":19,"column":22},"end":{"line":19,"column":53}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":24,"column":23}}})) != null ? stack1 : "")
    + "                <input type=\"radio\" id=\"sidebar-topic-followed-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias2(alias4(alias3(lookupProperty(depth0,"all_visibility_policies"), "FOLLOWED", {"start":{"line":25,"column":155},"end":{"line":25,"column":187}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":25,"column":197},"end":{"line":25,"column":252}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":191},"end":{"line":25,"column":268}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-followed-policy\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":147},"end":{"line":26,"column":162}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":184},"end":{"line":26,"column":199}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-follow\" aria-hidden=\"true\"></i>\n                </label>\n                <span class=\"slider\"></span>\n            </div>\n        </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"radio\" id=\"sidebar-topic-unmuted-policy\" class=\"tab-option\" name=\"sidebar-topic-visibility-select\" data-visibility-policy=\""
    + alias1(container.lambda(container.strict(lookupProperty(depth0,"all_visibility_policies"), "UNMUTED", {"start":{"line":20,"column":154},"end":{"line":20,"column":185}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":20,"column":195},"end":{"line":20,"column":249}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":189},"end":{"line":20,"column":265}}})) != null ? stack1 : "")
    + " />\n                <label role=\"menuitemradio\" class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"sidebar-topic-unmuted-policy\" aria-label=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":146},"end":{"line":21,"column":161}}}))
    + "\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":183},"end":{"line":21,"column":198}}}))
    + "\" tabindex=\"0\">\n                    <i class=\"zulip-icon zulip-icon-unmute-new\" aria-hidden=\"true\"></i>\n                </label>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator\"></li>\n            <li role=\"none\" class=\"popover-menu-list-item text-item italic\">\n                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"There are no messages in this topic.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":49},"end":{"line":36,"column":94}}}))
    + "</span>\n            </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"separator\" class=\"popover-menu-separator\"></li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_starred_messages"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_unread_messages"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-copy-link-to-topic popover-menu-link\" data-clipboard-text=\""
    + alias2(container.lambda(container.strict(depth0, "url", {"start":{"line":65,"column":120},"end":{"line":65,"column":123}} ), depth0))
    + "\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Copy link to topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":53},"end":{"line":67,"column":80}}}))
    + "</span>\n                </a>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"can_move_topic"),lookupProperty(depth0,"can_rename_topic"),lookupProperty(depth0,"is_realm_admin"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":71,"column":18},"end":{"line":71,"column":69}}}),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":12},"end":{"line":73,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_move_topic"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":74,"column":12},"end":{"line":88,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"can_rename_topic"),lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_empty_string_topic"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":89,"column":40},"end":{"line":89,"column":67}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":89,"column":18},"end":{"line":89,"column":68}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":12},"end":{"line":101,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_realm_admin"),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":12},"end":{"line":109,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_realm_admin"),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":12},"end":{"line":117,"column":19}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"sidebar-popover-unstar-all-in-topic popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-star\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Unstar all messages in topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":53},"end":{"line":45,"column":90}}}))
    + "</span>\n                </a>\n            </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"sidebar-popover-mark-topic-read popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-read\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":53},"end":{"line":53,"column":87}}}))
    + "</span>\n                </a>\n            </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item hidden-for-spectators\">\n                <a role=\"menuitem\" class=\"sidebar-popover-mark-topic-unread popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as unread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":53},"end":{"line":60,"column":89}}}))
    + "</span>\n                </a>\n            </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "            <li role=\"separator\" class=\"popover-menu-separator\"></li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-move-topic-messages popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Move topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":53},"end":{"line":78,"column":72}}}))
    + "</span>\n                </a>\n            </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"can_rename_topic"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":12},"end":{"line":88,"column":12}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-rename-topic-messages popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-rename\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Rename topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":53},"end":{"line":85,"column":74}}}))
    + "</span>\n                </a>\n            </li>\n            ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-toggle-resolved popover-menu-link\" tabindex=\"0\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"topic_is_resolved"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":92,"column":20},"end":{"line":98,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n            </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"popover-menu-icon zulip-icon zulip-icon-check-x\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":53},"end":{"line":94,"column":79}}}))
    + "</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"popover-menu-icon zulip-icon zulip-icon-check\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":53},"end":{"line":97,"column":77}}}))
    + "</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-delete-topic-messages popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":53},"end":{"line":106,"column":73}}}))
    + "</span>\n                </a>\n            </li>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li role=\"none\" class=\"link-item popover-menu-list-item\">\n                <a role=\"menuitem\" class=\"sidebar-popover-summarize-topic popover-menu-link\" tabindex=\"0\">\n                    <i class=\"popover-menu-icon fa fa-magic\" aria-hidden=\"true\"></i>\n                    <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Summarize recent messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":53},"end":{"line":114,"column":86}}}))
    + "</span>\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"topic-actions-menu-popover\" data-simplebar data-simplebar-tab-index=\"-1\">\n    <ul role=\"menu\" class=\"popover-menu-list\">\n        <li role=\"none\" class=\"popover-topic-header text-item popover-menu-list-item\">\n            <span class=\"popover-topic-name "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_empty_string_topic"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":99}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "topic_display_name", {"start":{"line":4,"column":103},"end":{"line":4,"column":121}} ), depth0))
    + "</span>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_spectator"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_topic_empty"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":118,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});